import React, { useState, useEffect } from 'react';
import './hero.css';
import { images } from '../../constants';
import { SlArrowRight } from 'react-icons/sl';

const heroContent = {
  patchNote: "Updates coming soon",

}

const heroTagLines = [
  "In need of a training program?",
  "Want to stop using the notes app?",
  "Need some guidance?",
  "Want to try some new exercises?",
  "Example slogan",
  "Example slogan",
  "Example slogan",
]

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % heroTagLines.length);
    }, 4000);
    return () => clearInterval(intervalId);
  }, []);

  const getTagline = (index) => {
    return heroTagLines[(index + heroTagLines.length) % heroTagLines.length];
  };

  const handleAnimationEnd = (event) => {
    event.target.classList.remove("animate");
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".app__animatedTaglineCarousel h1");
    elements.forEach(element => {
      element.addEventListener("animationend", handleAnimationEnd);
    });
    return () => {
      elements.forEach(element => {
        element.removeEventListener("animationend", handleAnimationEnd);
      });
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".app__animatedTaglineCarousel h1");
    elements.forEach(element => {
      element.classList.add("animate");
    });
  }, [currentIndex]);

  return (
    <section id='hero' className='app__hero app__bg'>
      <div className="app__hero-container">
        <div className="app__hero-content">
          <h2>Hey there, champ.</h2>
          <div className="app__hero-content-carousel app__animatedTaglineCarousel">
            <h1 style={{ opacity: 0, transform: "rotateX(40deg)" }}>{getTagline(currentIndex - 2)}</h1>
            <h1 style={{ opacity: 0.3, transform: "rotateX(20deg)" }}>{getTagline(currentIndex - 1)}</h1>
            <h1>{getTagline(currentIndex)}</h1>
            <h1 style={{ opacity: 0.3, transform: "rotateX(20deg)" }}>{getTagline(currentIndex + 1)}</h1>
            <h1 style={{ opacity: 0, transform: "rotateX(-40deg)" }}>{getTagline(currentIndex + 2)}</h1>
          </div>
          <h3>Get your workout plan today!</h3>
        </div>
        <div className="app__hero-img">
          <img src={images.hero} alt=""/>
        </div>
      </div>
      <div className='app__ctaButton1'>
        <a href="" className='app__ctaButton1-cta'>
          Go to the builder
          <SlArrowRight className='app__ctaButton1-arrow1'/>
          <SlArrowRight className='app__ctaButton1-arrow2'/>
          <SlArrowRight className='app__ctaButton1-arrow3'/>
        </a>
      </div>
      <div className="app__hero-signature">
        <img src={images.logo} alt="" />
        <div>
          <h5>Made by Yoran Schouppe</h5>
          <a href="https://yoranschouppe.com/" target='_blank'>Check out my portfolio</a>
        </div>
      </div>
    </section>
  )
}

export default Hero